<template>
<div class="d-flex flex-row d-flex justify-content-between" style="width:100%" >
<div class="p-2">
<img src="../../assets/mobile_app.png" width="125" class="float-left" @click="download()" />
</div>
<div class="p-2">
<p class="alert alert-success" @click="download()">To access the   full functionality   Download MyPaaa app</p>
<button class="btn btn-warning float-right" @click="download()"><i class="fa fa-download" aria-hidden="true"></i>Download App</button>
</div> 
</div>
</template>
<script>

export default {
  inject: ['appDownloadUrl'],
  name: "footer",
  data() {
    return {
     
    };
  },
  methods: {
    download(){
    window.location.href = this.appDownloadUrl
    }
  }
}

</script>