import http from "../http-common";

class MyPaaaDataService {

  get(id) {
    return http.get(`/d/${id}`);
  }

  getOrg(id) {
   return http.get(`/o/${id}`);
  }
  getEvent(id) {
   return http.get(`/e/${id}`);
  }

  updateEvent(id, data) {
    return http.patch(`/rsvpweb/${id}`, data);
  }
  updateAppointment( data) {
    return http.post(`/appointmentWeb`, data);
  }
  updateBooking( data) {
    return http.post(`/webBooking`, data);
  }
  delete(id) {
    return http.delete(`/tutorials/${id}`);
  }

  deleteAll() {
    return http.delete(`/tutorials`);
  }

  findByTitle(title) {
    return http.get(`/tutorials?title=${title}`);
  }
  checkCountryCode(data=[],value=''){
    var prefix="";
    for(let i=0;i<data.length;i++){
        if(value.indexOf(data[i].phoneCode)==0){
          prefix = data[i].phoneCode;
          return prefix;
        }
      }
    return prefix;
  }
  addPrefix(prefix,value){
    if(prefix.charAt(prefix.length-1) === value.charAt(0)){
          return prefix +  value.substring(1);
    }else{
      return  prefix+ value;
    }
  }

  getSlotMinutes(startTime,EndTime,slots,booked=[],d=new Date()){
      //var d= new Date();
      var sTime = this.convertTime12to24(startTime);
      var eTime =  this.convertTime12to24(EndTime);
      var startDt = new Date(d.getFullYear(),d.getMonth(), d.getDate() , 
      sTime.h, sTime.m, 0);
      const end = new Date(d.getFullYear(),d.getMonth(), d.getDate() , 
      eTime.h, eTime.m, 0);
     // alert(start +"     ====    "+end);
      const diff =  Math.abs(end-startDt);
      const minutes =  Math.floor((diff/1000)/60); //   parseInt(Math.abs(end.getTime() - start.getTime()) / (1000 * 60) % 60);
      const minPerSlots = Math.round(minutes/slots) ;
     // alert(startDt+'='+end);
     // alert(minPerSlots);
      var data=[];
      var bookedSlot=[];
      //var endR = start;
      for (var s =0; s <slots; s++) {
         var  temp = startDt ;
             var endDt =  new Date(temp.getTime() + (minPerSlots * 60 * 1000));
             if(temp>new Date()){
              bookedSlot  = booked.filter(entry => entry.yyyy == temp.getFullYear() &&  entry.mm == temp.getMonth() && entry.dd == temp.getDate() && entry.session == s);
             }else{
              bookedSlot=[0];
             }
              //alert(startDt+" ==== " + endDt );
            let object={
              startTime: startDt,
              endTime: endDt,
              booked: bookedSlot.length>0?true:false
            }
           
          //alert(object.startTime +" ==== " + object.endTime );
          startDt = endDt ;
         data.push(object);
        }
    return data;
  }
  convertTime12to24(time12h){
    const [time, modifier] = time12h.split(' ');
  
    let [hours, minutes] = time.split(':');
  
    if (hours === '12') {
      hours = '00';
    }
  
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
  
    return {h:hours,m:minutes};
  }
  fitEvents(data){
    data.forEach(function(obj) {
      obj.start = obj.startTime;
      obj.end = obj.endTime;
      obj.title = '';
      obj.content = '';
      obj.class = '';
      delete obj.endTime;
      delete obj.startTime;
    });
  }
  getUTC (dateObj) {
   // var now = new Date;
    var utc_timestamp = Date.UTC(dateObj.getFullYear(),dateObj.getMonth(), dateObj.getDate() , 
    dateObj.getHours(), dateObj.getMinutes(), dateObj.getSeconds(), dateObj.getMilliseconds());

    return utc_timestamp;
 }
}

export default new MyPaaaDataService();
